<template>
    <Header></Header>
    <br>
    <br>
    <Roadmap></Roadmap>
    <br>
    <br>
    <br>
    <br>
    <Features></Features>
    <Models></Models>
    <!-- <CustomerReviews></CustomerReviews> -->
    <Pricing></Pricing>
    <Footer></Footer>
</template>

<style scoped></style>

<script lang="js">
import { defineComponent, onMounted } from 'vue';
import Swal from 'sweetalert2';
import Header from './components/Header';
import Features from './components/Features';
import Models from './components/Models';
import CustomerReviews from './components/CustomerReviews';
import Pricing from './components/Pricing';
import Footer from './components/Footer';
import Roadmap from './components/Roadmap';
import { showToast } from '../../utils/utils.js';


export default defineComponent({
    name: 'LandingPage',
    components: {
        Header,
        Features,
        Models,
        CustomerReviews,
        Pricing,
        Footer,
        Roadmap
    },
    mounted() {
        this.checkLoginStatus();
    },
    methods: {
        checkLoginStatus() {
            setTimeout(() => {
                const accessToken = localStorage.getItem("access_token");
                const loginStatus = localStorage.getItem("login_status");

                if (accessToken) {
                    showToast("success", "Successful login");
                } else if (loginStatus == "logout") {
                    localStorage.removeItem('login_status');
                    showToast("success", "Successful logout");
                } else if (loginStatus == "register") {
                    localStorage.removeItem('login_status');
                    showToast("success", "Successful registration");
                } else if (loginStatus == "password_reset") {
                    localStorage.removeItem('login_status');
                    showToast("warning", "Please check your e-mail !");
                } else {
                    const urlParams = new URLSearchParams(window.location.search);
                    
                    // google login
                    if (urlParams.get('token')) {
                        localStorage.setItem('access_token', urlParams.get('token'));
                        console.log('Token guardado:', urlParams.get('token'));
                        window.history.replaceState(null, null, window.location.pathname);
                        window.location.reload();
                    } else {
                        showToast("info", "You are not logged !");
                    }
                }
            }, 1000);
        },
    }
})
</script>